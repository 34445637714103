import { Badge, Container, ExpandableSection, Header, SpaceBetween } from "@cloudscape-design/components";

function getAdditionalInfo(item) {
    const badges = []
    item.DocumentAttributes.forEach(element => {
        if (element.Key === "_excerpt_page_number") {
            badges.push(<Badge>Page {element.Value.LongValue}</Badge>)
        }
    })
    return badges

}

function generateHighlitedText(item) {
    let text = item.Text
    item.Highlights.forEach( highlight => {
        text = text.replace(item.Text.substr(highlight.BeginOffset, highlight.EndOffset), '<b>' + item.Text.substr(highlight.BeginOffset, highlight.EndOffset) + '</b>');
    })
    return text
}

function ResultItem(props) {
    
    return (<Container
        header={
            <Header
                variant="h2"
                description={props.resultItem.DocumentURI.slice(74)}
            >
                {props.resultItem.DocumentTitle.Text}
            </Header>
        }
        footer={
            <ExpandableSection
                headerText="Additional Information"
                variant="footer"
            >
                {getAdditionalInfo(props.resultItem)}
            </ExpandableSection>
        }
    >
        <div dangerouslySetInnerHTML={{ __html: generateHighlitedText(props.resultItem.DocumentExcerpt)}}></div>
    </Container>)
}


export default function ResultItems(props) {
    const items = []
    if (props.resultItems) {
        props.resultItems.forEach(element => {
            items.push(<ResultItem resultItem={element} />);
        });
    }
    return (<SpaceBetween
        size="l"
    >{items}</SpaceBetween>)
}