import React from 'react';
import { useNavigate } from 'react-router-dom';
import SideNavigation from '@cloudscape-design/components/side-navigation';

export function MainNavigation({
  items
}) {
  const navigate = useNavigate()
  return <SideNavigation items={items} activeHref={window.location.pathname} onFollow={(event) => {
    event.preventDefault();
    navigate(event.detail.href)
  }} />;
}