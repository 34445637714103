import { ContentLayout } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";

export default function NotFound () {
    return (
        <ContentLayout 
        header={
          <AppHeader
            breadcrumbs={[
                {text:"Ask your Documents", href: "/"}
            ]}
            header={"Error 404 - page not found"}
            description="Diese Seite konnte nicht gefunden werden. Überprüfe den Link & versuche es erneut!"
          />
        }>
            {/* Hier kommt der eigentliche Content hin */}
        </ContentLayout>
      );
}