import React from "react";
import AppLayout from '@cloudscape-design/components/app-layout';
import { MainNavigation } from "./commons/navigation";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/commons/404";
import { TopNavigation } from "@cloudscape-design/components";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import LoginPage from "./pages/commons/login";
import ContractSearch from "./pages/contract-search/contract-search";

const pages = [
  { text: 'Provider Management', type: 'section', items: [
    { text: 'Cheat Sheet', href: '/onboarding', type: 'link' },
    { text: 'Search', href: '/', type: 'link' },
  ] },
  { type: "divider" }
]

export default function App() {

  const authInstance = useMsal().instance;
  const isAuthenticated = useIsAuthenticated();
  const navigationUtils = []
  let sideNavigation = "";
  let navigationHide = true

  if (isAuthenticated){
    // modifying side navigation 
    sideNavigation = <MainNavigation items={pages}/>
    navigationHide = false
    // pushing profile to top-navigation
    navigationUtils.push({
      type: 'menu-dropdown',
      text: authInstance.getAllAccounts()[0].name,
      description: authInstance.getAllAccounts()[0].username,
      iconName: 'user-profile',
      items: [],
    })
    //pushing logout to top-navigation
    navigationUtils.push({
      type: 'button',
      text: 'logout',
      variant: 'primary-button',
      onClick: () => authInstance.logoutRedirect({
        postLogoutRedirectUri: "/login",
      }),
    })
  }

  return (
    <>
    <TopNavigation
      identity={{
        href: '/',
        title: 'Ask your Documents',
        logo: { src: process.env.PUBLIC_URL + '/logo.png', alt: 'Ask your Documents' },
      }}
      utilities={navigationUtils}
    />
    <AppLayout
      content={
        <Routes>
          {/* only Authenticated Access */}
          <Route path='/' Component={isAuthenticated ? ContractSearch : LoginPage}/>
          <Route path='*' Component={isAuthenticated ? NotFound : LoginPage}/>

          {/* only Unauthenticated Access */}
          <Route path='/login' Component={isAuthenticated ? ContractSearch: LoginPage}/>

          {/* both Access */}
        </Routes>
      }
      navigation={sideNavigation}
      navigationHide={navigationHide}
      toolsHide={true}
    />
    </>
  );
}