import { BreadcrumbGroup, Header } from "@cloudscape-design/components";

export function AppHeader(props) {
    return (
      <Header variant="h1"
        actions={
          props.actions
        }
        description= {props.description}>
        <BreadcrumbGroup items={props.breadcrumbs}></BreadcrumbGroup>
        {props.header}
      </Header>
    );
}