import { Alert, Badge, Box, Button, Container, ContentLayout, ExpandableSection, Grid, Header, Input, Spinner } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import { KendraClient, QueryCommand } from "@aws-sdk/client-kendra";
import * as React from "react";
import ResultItems from "./resultItems";

const creds = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
}

const kendra = new KendraClient({
  credentials: creds,
  region: "eu-west-1",
})

function getAdditionalInfo(item) {
  const badges = []
  item.DocumentAttributes.forEach(element => {
    if (element.Key === "_excerpt_page_number") {
      badges.push(<Badge>Page {element.Value.LongValue}</Badge>)
    }
  })
  return badges

}

function generateHighlitedText(item) {
  let text = item.Text
  item.Highlights.forEach(highlight => {
    text = text.replace(item.Text.substr(highlight.BeginOffset, highlight.EndOffset), '<b>' + item.Text.substr(highlight.BeginOffset, highlight.EndOffset) + '</b>');
  })
  return text
}

function getTopResult(resultItems) {
  if (resultItems && resultItems.length > 0) {
    return (<Container
      header={
        <Header
          variant="h2"
          description={resultItems[0].DocumentURI.slice(74)}
        >
          {resultItems[0].DocumentTitle.Text}
        </Header>
      }
      footer={
        <ExpandableSection
          header="Additional Information"
          variant="footer"
        >
          {getAdditionalInfo(resultItems[0])}
        </ExpandableSection>
      }
    >
      <div dangerouslySetInnerHTML={{ __html: generateHighlitedText(resultItems[0].DocumentExcerpt) }}></div>
    </Container>)
  }
}

export default function ContractSearch() {
  const [value, setValue] = React.useState("");
  const [results, setResults] = React.useState("");
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async () => {
    if (value) {
      setLoading(true)
      const input = {
        AttributeFilter: {
          AndAllFilters: [
            {
              EqualsTo: {
                Key: "_language_code",
                Value: {
                  StringValue: "de"
                }
              }
            }
          ]
        },
        IndexId: "4aa4f75a-064d-4182-997d-da637f053e6d",
        PageNumber: 1,
        PageSize: 100,
        DocumentRelevanceOverrideConfigurations: [
          {
            Name: "created_at",
            Relevance: {
              Importance: 7,
              RankOrder: "ASCENDING"
            }
          },
          {
            Name: "document_title",
            Relevance: {
              Importance: 5,
              RankOrder: "ASCENDING"
            }
          }
        ],
        QueryText: value,
        SpellCorrectionConfiguration: {
          IncludeQuerySpellCheckSuggestions: true
        }
      }
      console.log(creds)
      const command = new QueryCommand(input);
      const response = await kendra.send(command);
      setLoading(false)
      setResults(response)
    }

  }

  const handleKeyDown = (event) => {
    // look for the `Enter` keyCode
    if (event.detail.keyCode === 13) {
      handleSubmit()
    }
  }

  return (
    <ContentLayout
      header={
        <Box>
          <Alert
            statusIconAriaLabel="Info"
            header="Neuste Features"
          >
            Die Highlights in den Antworten werden jetzt <b>Fett</b> markiert<br />
            Loading Spinner
          </Alert>
          <AppHeader
            breadcrumbs={[
              { text: "Ask Your Documents", href: "/" },
              { text: "Provider Management", href: "/" },
              { text: "Search", href: "/" },
            ]}
            header={"Provider Managemment"}
            description="Hier kannst du alle deine Dokumente durchsuchen"
          />
        </Box>
      }>
      {/* Hier kommt der eigentliche Content hin */}

      <Grid
        gridDefinition={[
          { colspan: { default: 12 } },
          { colspan: { default: 12 } },
          { colspan: { default: 12 } }
        ]}
      >
        <Container>
          <Grid
            gridDefinition={[
              { colspan: { default: 10 } },
              { colspan: { default: 2 } }
            ]}
          >
            <Input
              onChange={({ detail }) => setValue(detail.value)}
              value={value}
              autoFocus
              onKeyDown={handleKeyDown}
              placeholder="Search here now"
              spellcheck
              type="search"
            />
            <Box float="center">
              <Button
                fullWidth
                iconName="search"
                variant="primary"
                onClick={handleSubmit}
              >
                Search
              </Button>
            </Box>
          </Grid>
        </Container>
        <Container
          header={<Header>
            Top Result
          </Header>}
        >
          {loading ? <Spinner size="large" /> : getTopResult(results.ResultItems)}
        </Container>
        <Container
          header={<Header counter={results.TotalNumberOfResults}>
            Results
          </Header>}
        >
          {loading ? <Spinner size="large" /> : <ResultItems resultItems={results.ResultItems?.slice(1)} />}
        </Container>
      </Grid>
    </ContentLayout>
  );
}