import { useMsal } from '@azure/msal-react';
import { Button, Container, ContentLayout, Header, SpaceBetween } from '@cloudscape-design/components';
import { loginRequest } from '../../commons/authConfig';
import { AppHeader } from '../../commons/header';

export default function LoginPage(){
  const { instance } = useMsal();

  return (
    <ContentLayout 
      header={
        <AppHeader
          breadcrumbs={[
            {text:"Ask Your Documents", href: "/"},
            {text:"login", href: "/login"},
          ]}
          header={"Welcome to Ask your Documents"}
          description="Search all your documents"
        />
      }>
      {/* Hier kommt der eigentliche Content hin */}
      <Container
        header={
          <Header
            variant="h2"
            description="Search all your Documents in one Place"
            actions={
              <SpaceBetween
                direction="horizontal"
                size="xs"
              >
                <Button variant='primary' onClick={() => instance.loginRedirect(loginRequest).catch((e) => console.log(e))}>Sign in</Button>
              </SpaceBetween>
            }
          >
            Sign in to Your RheinEnergie-Account
            
          </Header>
        }
      >
    <img alt='ayd' src="logo.gif" className='animatedLogo' />
      </Container>
    </ContentLayout>
  );
};